import { FC, createContext, useContext } from 'react';
import Notification from '~/components/general/Notification';
import { UseNotificationsReturn, useNotifications } from '../hooks';

export type INotificationContext = Pick<
  UseNotificationsReturn,
  | 'openNotification'
  | 'tentativeRemoved'
  | 'tentativelyRemove'
  | 'undoTentativeRemove'
>;

const NotificationContext = createContext<INotificationContext>({
  openNotification: () => {},
  tentativelyRemove: () => {},
  undoTentativeRemove: () => {},
  tentativeRemoved: [],
});

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'Make sure to use NotificationContext inside of the provider'
    );
  }
  return context;
};

export const NotificationContextWrapper: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const {
    notifications,
    openNotification,
    closeNotification,
    tentativeRemoved,
    tentativelyRemove,
    undoTentativeRemove,
    forceNotificationClose,
  } = useNotifications();

  return (
    <NotificationContext.Provider
      value={{
        openNotification,
        tentativeRemoved,
        tentativelyRemove,
        undoTentativeRemove,
      }}
    >
      {notifications.map((n) => (
        <Notification
          key={n.id}
          show={n.show}
          type={n.type}
          className={n.className}
          persistent={n.persistent}
          customAction={n.customAction}
          close={() => closeNotification(n.id)}
          description={n.description}
          innerKey={n.id}
          withUndo={n.withUndo}
          onCloseComplete={(origin) => {
            forceNotificationClose(n.id);
            n.onCloseComplete?.(origin);
          }}
          onUndo={
            n.withUndo
              ? () => {
                  n.onUndo?.();
                  closeNotification(n.id);
                }
              : undefined
          }
        />
      ))}
      {children}
    </NotificationContext.Provider>
  );
};
