import { useState } from 'react';
import { NotificationProps } from '~/components/general/Notification';

interface Notification
  extends Pick<
    NotificationProps,
    | 'description'
    | 'withUndo'
    | 'onUndo'
    | 'onCloseComplete'
    | 'show'
    | 'persistent'
    | 'className'
    | 'type'
    | 'customAction'
  > {
  id: string;
}

interface OpenNotification extends Omit<Notification, 'show'> {}

export interface UseNotificationsReturn {
  notifications: Notification[];
  tentativeRemoved: string[];
  openNotification(notification: OpenNotification): void;
  closeNotification(id: string): void;
  tentativelyRemove(id: string): void;
  undoTentativeRemove(id: string): void;
  forceNotificationClose(id: string): void;
}

export const useNotifications = (): UseNotificationsReturn => {
  const [notifications, setNotifications] = useState<Map<string, Notification>>(
    new Map()
  );
  const [tentativeRemoved, setTentativeRemoved] = useState<string[]>([]);

  const openNotification = (notification: OpenNotification) => {
    const oldMap = new Map(notifications);
    oldMap.set(notification.id, { ...notification, show: true });
    setNotifications(oldMap);
  };

  const closeNotification = (id: string) => {
    const oldMap = new Map(notifications);
    const oldNotification = oldMap.get(id);
    if (!oldNotification) return;
    oldNotification.show = false;
    oldMap.set(id, oldNotification);
    setNotifications(oldMap);
  };

  const forceNotificationClose = (id: string) => {
    const oldMap = new Map(notifications);
    oldMap.delete(id);
    setNotifications(oldMap);
  };

  const tentativelyRemove = (id: string) => {
    const oldArray = [...tentativeRemoved];
    oldArray.push(id);
    setTentativeRemoved(oldArray);
  };

  const undoTentativeRemove = (id: string) => {
    setTentativeRemoved(tentativeRemoved.filter((t) => t !== id));
  };

  return {
    notifications: Array.from(notifications.keys()).map(
      (k) => notifications.get(k)!
    ),
    tentativeRemoved,
    openNotification,
    closeNotification,
    tentativelyRemove,
    undoTentativeRemove,
    forceNotificationClose,
  };
};
